@import '../../Variables/Variables.scss';

.contact-content {
    padding: 120px 0;
    background-color: $color-light-gray;

    @include breakpoint(tablet) {
        padding: 10px;
    }

    .title {
        margin-top: 0;
    }

    a {
        color: $secondary-color;

        &:hover {
            color: $primary-color;;
            text-decoration: none;
        }
    }
}