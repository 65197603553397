@import '../../Variables/Variables.scss';

.gallery-content {
    img {
        border-radius: 50px;
        padding: 15px;
    }

    .title {
        margin-left: 15px;
        @include breakpoint(tablet){
            text-align: center;
            margin-left: unset;
        }
    }
}