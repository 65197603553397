@import '../../Variables/Variables.scss';

.openAllDay {
    background-color: $third-color;
    width: 100%;
    border-radius: 100px;
    padding: 30px;

    @include breakpoint(tablet) {
        text-align: center;
        padding: 30px 20px;
        border-radius: 50px;
    }

    h1 {
        font-size: 3.5rem;
        font-weight: 600;
        color: $color-white;
        
        @include breakpoint(tablet) {
            font-size: 2.5rem;
            width: 80%;
            margin: 0 auto;
        }
    }

    a {
        color: $color-white;
        text-decoration: none;
        margin-left: 55px;

        &:first-of-type {
            margin-left: unset;
        }

        &:hover {
            color: $color-white;
            text-decoration: none;
        }

        @include breakpoint(tablet-large) {
            display: block;
            margin-left: unset;
            margin-bottom: 25px;

            &:last-of-type {
                margin-bottom: unset;
            }
        }
    }
}