@import '../../Variables/Variables.scss';

.navigation-bg {
    position: relative;
    width: 100%;
    height: 190px; 
    background-repeat: no-repeat;
    background-size: cover;
}

.container-nav {

    .navbar {
        margin-top: 30px;
    }

    .navbar-collapse {
        z-index: 100;
    }

    .navbar-nav {
        padding: 20px 0;
        border-radius: 50px;

        .nav-item {
            text-transform: uppercase;
            font-weight: 600;
            margin-left: 30px;

            &:last-of-type {
                margin-right: 30px;
            }

            a {
                color: $primary-color;
                font-size: 16px;

                &:active, &:focus, &:hover {
                    color: $secondary-color;
                }
            }
        }
    }
}