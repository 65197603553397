@import '../../Variables/Variables.scss';

.job-content-wrapp {
    background-color:$third-color;
    padding: 120px;

    @include breakpoint(tablet){
        padding: 25px;
    }

    .job-mail {
        text-decoration: none;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background-color: $color-white;
            width: 0;
            height: 4px;
            border-radius: 100px;
            left: 0;
            margin-bottom: 10px;
            bottom: -20px;
            transition: 0.5s;
        }
    }

    &:hover {
        
        .job-mail {

            &::before {
               width: 100%;
            }
        }
    }
}

.job-content {

    .job-desc {
        padding-left: 40px;

        @include breakpoint(tablet){
            padding-left: unset;
            margin-top: 40px;
        }
    }

    .job-mail {
        font-size: 18px;
        text-decoration: none;
        color: $color-white;

        &:hover {
            color: $color-white;
            text-decoration: none;
        }
    }

    .title {
        margin-top: 0;
    }

    .title, p {
        color: $color-white;
    }

    @include breakpoint(tablet){
        border-radius: 15px;
        padding: 30px;
    }
}