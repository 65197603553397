@import '../src/Variables/Variables.scss';

* {
  font-family: $app-font-family;
  color: $text-color;
  font-size: 17px;
}

.m-70 {
  margin-top: 70px;

  @include breakpoint(tablet){
    margin-top: 30px;
  }
}

.title {
  font-size: 3rem;
  text-transform: uppercase;
  margin: 90px 0 30px;

  @include breakpoint(tablet) {
      font-size: 2.5rem;
      margin: 40px 0;
  }
}

// back to top

#button {
  display: inline-block;
  background-color: $color-light-gray;
  width: 50px;
  height: 50px;
  text-align: center;
  border: 0;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s, 
  opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;

  &::after {
    content: "";
    position: absolute;
    left: 13px;
    z-index: 11;
    display: block;
    width: 25px;
    height: 25px;
    border-top: 2px solid $primary-color;
    border-left: 2px solid $primary-color;
    top: 20px;
    transform: rotate(45deg);
  }

  &:focus {
    outline: 0;
  }
}

#button:hover {
  cursor: pointer;
  background-color:$primary-color;

  &::after {
    border-top: 2px solid $color-light-gray;
    border-left: 2px solid $color-light-gray;
  }
}
#button:active {
  background-color:$primary-color;
  border: 0;
}
#button.show {
  opacity: 1;
  visibility: visible;
}