@import '../../Variables/Variables.scss';

.aboutUs {
    margin-top: -20px;

    .aboutUsImg {
        width: 100%;
    }

    .title {
        text-align: left;
    }
}