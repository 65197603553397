@import '../../Variables/Variables.scss';

.location-wrapp {
    padding: 70px 0;
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI1IiBoZWlnaHQ9IjUiPgo8cmVjdCB3aWR0aD0iNSIgaGVpZ2h0PSI1IiBmaWxsPSIjZmZmIj48L3JlY3Q+CjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNjY2MiPjwvcmVjdD4KPC9zdmc+");
}

.location-content {
    
    .title {
        margin-top: 0;
        @include breakpoint(tablet){
            text-align: center;
        }
    }
}

.telefon {
    color: $primary-color;

    &:hover {
        text-decoration: none;
        color: $third-color;
    }
}

.location-card {
    margin-top: 15px;
    padding: 0 !important;

    .card-wrapp {
        padding: 1.25rem;
    }

    .card-title {
        font-weight: 600;
    }

    .card-address {

        position: relative;
        &::before {
            content: '';
            position: absolute;
            background-color: $primary-color;
            width: 0;
            height: 4px;
            border-radius: 100px;
            left: 0;
            margin-bottom: 10px;
            bottom: -20px;
            transition: 0.5s;
        }
    }
   
    .card-body {
        background-color: $color-white;
        min-height: 330px;
        margin-bottom: 15px;
        padding: 2.25rem;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px;
            .card-address {

                position: relative;
                &::before {
                    width: 100%;
                }
            }
        }

        a {
            display: inline-block;
            &:hover {
                text-decoration: none;
            }
        }
    }
}